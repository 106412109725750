<template>
    <div class="pr-2">
        <highcharts
            :options="chartOptions"
            ref="lineCharts"
        ></highcharts>
        <v-divider></v-divider>
        <div>
            <template>
                <v-row justify="center">
                    <v-col cols="2" class="pt-4">
                        <v-select v-model="filters.year_value"
                            :items="list_years"
                            outlined                            
                            label="Año"
                            hide-details="auto"
                        ></v-select>
                    </v-col>
                    <v-col cols="3" class="pt-4">
                        <v-select v-model="filters.month_value"
                            :items="list_months"
                            item-value="id"
                            outlined                            
                            label="Mes"
                            hide-details="auto"
                        ></v-select>
                    </v-col>
                    <v-col cols="3" class="pt-4">
                        <v-btn color="indigo" dark @click="handleUpdateChart">
                            <v-icon size="14" class="mr-1">
                                fas fa-redo-alt
                            </v-icon>
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </div>
    </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";

import DashboardApi from '../../apis/Dashboard';
import Tools from '../../helpers/Tools';

export default {
    name: 'ProductsChart',
    components: {
        highcharts: Chart
    },
    data () {
        return {
            colors: [
                '#009FD7',
                '#00D99D',
                '#6800D5',
                '#FC3D55',
                '#FFAC00',
                '#666E73',
                '#A5BF7E',
                '#F2EDD0',
                '#F2B66D',
                '#D97B59',
                '#084056',
                '#188367',
                '#9EA66D',
                '#FF914D',
                '#F53005'
            ],
            dataReport: [],
            categories: [],
            dataValues: [],
            list_years: [],
            list_months: [],
            filters: {
                year_value: this.$moment().year(),
                month_value: this.$moment().month()+1
            },
            chartOptions: {
                chart: {
                    type: 'bar'
                },
                title: {
                    text: 'Productos más vendidos'
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Cantidad Vendida',
                        align: 'high'
                    },
                    labels: {
                        overflow: 'justify'
                    }
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                series: []
            }
        }
    },
    methods: {
        getSelledProducts () {
            DashboardApi.getMostSelledProducts(this.filters.year_value, this.filters.month_value)
                .then( response => {
                    this.dataReport = response.data;
                    this.prepareData();
                }).catch( error => {
                    console.log('Ocurrió un error')
                })
        },
        prepareData() {
            this.categories = []
            this.dataValues = [] 
            this.dataReport.forEach((element, index) => {
                this.categories.push(element.description);
                let itemValue = {
                    color: this.colors[index],
                    y: parseFloat(element.total)
                }
                this.dataValues.push(itemValue)
            })
            let valueSeries = []
            valueSeries.push({
                name: 'Productos Vendidos',
                data: this.dataValues
            })
            this.chartOptions.xAxis.categories = this.categories
            this.chartOptions.series = valueSeries
        },
        getMonthName (number_month) {
            let names = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Setiembre", "Octubre", "Noviembre", "Diciembre"];

            return names[number_month - 1];
        },
        generateListYears() {
            this.list_years = [];
            let start_year = 2020;
            let end_year = this.$moment().year();
            for (let i = start_year; i <= end_year; i++) {
                this.list_years.push(i);
            }
        },
        handleUpdateChart () {
            let month_name = this.getMonthName(this.filters.month_value);
            this.chartOptions.title.text = 'Productos más vendidos en ' + month_name;
            this.getSelledProducts();
        },
        loadData () {
            this.list_months = Tools.getMonths();
            this.generateListYears();
            let month_name = this.getMonthName(this.filters.month_value);
            this.chartOptions.title.text = 'Productos más vendidos en ' + month_name;
            this.getSelledProducts();
        }
    },
    created () {
        this.loadData();
    }
}
</script>

<style scoped>

</style>