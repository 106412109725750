export default {
    getMonths () {
        return [
            {
                id: 1,
                text: "Enero" 
            },
            {
                id: 2,
                text: "Febrero" 
            },
            {
                id: 3,
                text: "Marzo" 
            },
            {
                id: 4,
                text: "Abril" 
            },
            {
                id: 5,
                text: "Mayo" 
            },
            {
                id: 6,
                text: "Junio" 
            },
            {
                id: 7,
                text: "Julio" 
            },
            {
                id: 8,
                text: "Agosto" 
            },
            {
                id: 9,
                text: "Setiembre" 
            },
            {
                id: 10,
                text: "Octubre" 
            },
            {
                id: 11,
                text: "Noviembre" 
            },
            {
                id: 12,
                text: "Diciembre" 
            },
        ]
    },
    getRandomInt (min, max) {
        return Math.floor( Math.random() * ( max - min + 1 ) ) + min;
    },
    generateBarcode () {
        this.length = 9;
        this.timestamp = +new Date;		 
        
        var ts = this.timestamp.toString();
        var parts = ts.split( "" ).reverse();
        var id = "";
        
        for( var i = 0; i < this.length; ++i ) {
            var index = this.getRandomInt( 0, parts.length - 1 );
            id += parts[index];	 
        }
        
        return id;
    },
    findIndexFromItem(data, idSearched) {
        let indexFounded = -1
        data.forEach( (element, index) => {
            if (element.id === idSearched) {
                indexFounded = index
            }
        })

        return indexFounded
    },
}