<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col cols="6" class="pb-1 pt-0">
          <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-col cols="6" class="pb-1 pt-0 h-100">
          <office-login></office-login>
        </v-col>
      </v-row>
      <v-row v-if="selected_till && selected_till.status == 'abierta'">
        <v-col cols="7">
          <v-card>
            <products-chart></products-chart>
          </v-card>
        </v-col>
        <v-col cols="5">
          <v-card>
            <sales-chart></sales-chart>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <closed-till></closed-till>
      </v-row>
    </template>
  </v-container>
  <!--
    <div>
        <p>
            <v-icon size="16" @click="handleViewMenu" class="ml-2 mr-2 d-md-none">
                fas fa-bars
            </v-icon>
            Dashboard
        </p>
    </div>
    -->
</template>

<script>
import { mapActions, mapState } from "vuex";
import OfficeLogin from "../../components/auth/OfficeLogin";

import ProductsChart from "../../components/dashboard/ProductsChart";
import SalesChart from "../../components/dashboard/SalesChart";
import ClosedTill from "../../components/tills/ClosedTill";

export default {
  name: "Dashboard",
  components: {
    OfficeLogin,
    ProductsChart,
    SalesChart,
    ClosedTill,
  },
  data() {
    return {
      itemsBreadcrumbs: [
        {
          text: "Dashboard",
          disabled: true,
          href: "",
        },
      ],
    };
  },
  computed: {
    ...mapState("authentication", ["office_id", "till_user_id"]),
    ...mapState("tills", ["tills", "selected_till"]),
  },
  methods: {
    ...mapActions("notifications", [
      "can_show_notification",
      "store_notification",
    ]),
    ...mapActions("authentication", ["setShowMenu"]),
    ...mapActions("tills", ["getAvailable", "select_till"]),
    handleViewNotification() {
      this.can_show_notification(true);
      this.store_notification({
        type: "success",
        message: "este es un mensaje de prueba",
      });
    },
    async getCurrentUserTill() {
      let filters = {
        till_id: this.till_user_id,
      };
      await this.getAvailable(filters);
      if (this.tills.length > 0) {
        this.select_till(this.tills[0]);
      }
    },
    handleViewMenu() {
      this.setShowMenu(true);
    },
  },
  created() {
    this.getCurrentUserTill();
  },
};
</script>

<style scoped>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>
