<template>
    <div >
        <highcharts
            :options="chartOptions"
            ref="lineCharts"
        ></highcharts>
    </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";

import DashboardApi from '../../apis/Dashboard';

export default {
    name: 'SalesChart',
    components: {
        highcharts: Chart
    },
    data () {
        return {
            colors: [
                '#009FD7',
                '#00D99D',
                '#6800D5',
                '#FC3D55',
                '#FFAC00',
                '#666E73',
                '#A5BF7E',
                '#F2EDD0',
                '#F2B66D',
                '#D97B59',
                '#084056',
                '#188367',
                '#9EA66D',
                '#FF914D',
                '#F53005'
            ],
            dataReport: [],
            categories: [],
            dataValues: [],
            filters: {
                year_value: this.$moment().year()
            },
            chartOptions: {
                chart: {
                    type: 'column'
                },
                title: {
                    text: 'Ventas por mes'
                },
                xAxis: {
                    categories: [],
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Monto (S/)'
                    }
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                credits: {
                    enabled: false
                },
                series: []
            }
        }
    },
    methods: {
        getSelledMonth () {
            DashboardApi.getSalesPerMonth(this.filters.year_value)
                .then( response => {
                    this.dataReport = response.data;
                    this.prepareData();
                }).catch( error => {
                    console.log('Ocurrió un error')
                })
        },
        prepareData() {
            this.categories = []
            this.dataValues = [] 
            this.dataReport.forEach((element, index) => {
                this.categories.push(element.sales_month_name);
                let itemValue = {
                    color: this.colors[index],
                    y: parseFloat(element.sale_total)
                }
                this.dataValues.push(itemValue)
            })
            let valueSeries = []
            valueSeries.push({
                name: 'Total',
                data: this.dataValues
            })
            this.chartOptions.xAxis.categories = this.categories
            this.chartOptions.series = valueSeries
        }
    },
    created () {
        this.getSelledMonth();
    }
}
</script>

<style scoped>

</style>